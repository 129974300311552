<template>
<div class="page membersort">
  <headers title="抢单" :showLeft="false"></headers>
  <div class="scroll">
    <div class="guard">
      <img src="../../../static/img/dosnfh.png" class="tufhs" alt="">

      <div class="grade">{{userVipInfo.level}}</div>
      <div class="plus">铜牌会员专属通道 佣金{{userVipInfo.yongjin}}</div>
      <div class="grabAnOrder">
        <div class="xiahfs xiahfs1">
          <div id="machine1" class="randomizeMachine">
            <div class="img"></div>
            <div class="img"></div>
            <div class="img"></div>
            <div class="img"></div>
            <div class="img"></div>
            <div class="img"></div>
            <div class="img"></div>
          </div>
        </div>
        <div class="xiahfs xiahfs2">
          <div id="machine2" class="randomizeMachine">
            <div class="img"></div>
            <div class="img"></div>
            <div class="img"></div>
            <div class="img"></div>
            <div class="img"></div>
            <div class="img"></div>

          </div>
        </div>
        <div class="xiahfs xiahfs3">
          <div id="machine3" class="randomizeMachine">
            <div class="img"></div>
            <div class="img"></div>
            <div class="img"></div>
            <div class="img"></div>
            <div class="img"></div>
            <div class="img"></div>
            <div class="img"></div>

          </div>
        </div>

      </div>
      <div class="quanli">全力派单中，派单结果将在下方发放。</div>
    </div>
    <div class="shoudo" @click="makeMoneyManually()">手动赚钱</div>
    <div class="fsfassets">
      <div class="biaoti">总资产（元）</div>
      <div class="jiege">{{money}}</div>
      <ul class="diyiba">
        <li>
          <div class="money">{{todayzhanguoInfo.yester_yj}}</div>
          <div class="text">昨日收益</div>
        </li>
        <li>
          <div class="money">{{todayzhanguoInfo.today_yj}}</div>
          <div class="text">今日已抢佣金</div>
        </li>
        <li>
          <div class="money">{{todayzhanguoInfo.my_todayconvey}}</div>
          <div class="text">今天已抢单数</div>
        </li>
        <li>
          <div class="money">{{todayzhanguoInfo.yesterteam_yj}}</div>
          <div class="text">昨日团队佣金</div>
        </li>
      </ul>
    </div>
    <div class="fanyongList" v-if="fanyongList.length>0">
      <vue-seamless-scroll :data="fanyongList" :class-option="defaultOption">
        <ul :style="'transform:translate(0px,'+listTop+'px'">
          <li v-for="(item,index) in fanyongList" :key="index">
            <p>{{item.phone}}</p>
            <p>{{item.info}}</p>
            <p><span>{{item.money}}</span>元</p>
          </li>
        </ul>
      </vue-seamless-scroll>


    </div>


    <van-popup v-model="showPopup" :close-on-click-overlay="false" round>
      <div class="popup-container">
        <div class="title">恭喜抢单成功</div>
        <div class="closeIcon" @click="showPopup = false">
          <van-icon name="cross" color="#fff"/>
        </div>
        <div class="content">
          <div class="info">
            <p>抢单时间：{{ orderInfo.addtime }}</p>
            <p>抢单编号：{{ orderInfo.id }}</p>
            <div class="dengdan">订单匹配成功，请稍等......</div>
          </div>
          <img src="../../../static/img/qiangdna.png" class="qiangdna" alt="">
        </div>
      </div>
    </van-popup>
  </div>
</div>
</template>
<script>
import vueSeamlessScroll from 'vue-seamless-scroll'

import headers from '@/components/headers.vue'

import SlotMachine from 'jquery-slotmachine/dist/slotmachine.min'
import 'jquery-slotmachine/dist/slotmachine.css'

export default {
  name: "membersort",
  components: {
    headers,
    vueSeamlessScroll
  },
  data() {
    return {
      machine1: null,
      machine2: null,
      machine3: null,

      showPopup: false,

      money:0,
      userVipInfo: {},
      orderInfo: {},
      todayzhanguoInfo: {},
      fanyongList: [],

      listTop: 0,



    }
  },
  mounted() {
    this.machine1 = new SlotMachine(document.getElementById('machine1'), {
      active: 2,
    });
    this.machine2 = new SlotMachine(document.getElementById('machine2'), {
      active: 2,
    });
    this.machine3 = new SlotMachine(document.getElementById('machine3'), {
      active: 2,

    });
    this.getUserVIP();
    this.getTodayzhanguo()
    this.getMoney()
    this.getFanyongFist()
  },
  // 监听属性 类似于data概念
  computed: {
    defaultOption () {
      return {
        step: 0.8, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }

  },
  methods: {
    makeMoneyManually() {
      var that = this;
      var clickBtn = document.getElementsByClassName('shoudo');
      clickBtn[0].style.pointerEvents = "none";
      this.machine1.shuffle(5, 0), setTimeout((function() {
        return that.machine2.shuffle(5)
      }), 500), setTimeout((function() {
        return that.machine3.shuffle(5)
      }), 1e3), setTimeout((function() {
        clickBtn[0].style.pointerEvents = "auto";
        return that.submitOrder();
      }), 1e3)
    },
    getMoney(){
      $api.shoudan({
        token: this.$store.getters['getToken'],
      }).then(res => {
        this.money = res.data.yue
      })
    },
    getUserVIP(){
      $api.getuservip({
        token: this.$store.getters['getToken']
      }).then(res => {
          this.userVipInfo = res.data
      })
    },
    getTodayzhanguo(){
      $api.todayzhanguo({
        token: this.$store.getters['getToken']
      }).then((res)=> {
        this.todayzhanguoInfo = res.message
      })
    },
    submitOrder(){
      $api.submit_order({
        token: this.$store.getters['getToken']
      }).then(res => {
        this.orderInfo = res.convey;
        this.showPopup = true;
      })
    },
    getFanyongFist(){
      $api.fanyonglist({
        token: this.$store.getters['getToken']
      }).then(res => {
        this.fanyongList = res.message;
      })
    },
  }
}
</script>

<style scoped lang="scss">
  .membersort {
    background: #f7f7f7;
    height: 100%;
    font-family: Roboto,sans-serif;
    padding-bottom: 20px;
    .guard {
      position: relative;
      background: #fff;
      border-radius: 10px;
      width: 94%;
      margin: 40px auto 30px;
      padding-top: 40px;
      padding-bottom: 20px;
      .tufhs {
        width: 52px;
        height: 52px;
        position: absolute;
        left: 25px;
        top: -15px;
      }
      .grade {
        font-size: 20px;
        font-weight: bold;
        text-align: left;
        padding-left: 10px;
      }
      .plus {
        text-align: left;
        padding-left: 10px;
        margin-top: 6px;
        color: #969696;
        font-size: 12px;
      }
      .grabAnOrder {
        background-image: url("../../../static/img/qiangdan.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 250px;
        margin-top: 15px;
        position: relative;
        .randomizeMachine {
          width: 100px;
          height: 100px;
        }
        .xiahfs {
          width: 100px;
          height: 70px;
          display: inline-block;
          overflow: hidden;
          .img {
            background: url("../../../static/img/wenhao.png") no-repeat;
            background-size: 100% 100%;
            width: 50px;
            height: 70px;
            margin: auto;
            position: relative;
            z-index: 999;
          }
        }
        .xiahfs1 {
          position: absolute;
          left: 37px;
          top: 115px;
        }
        .xiahfs2 {
          position: absolute;
          left: 130px;
          top: 115px;
        }
        .xiahfs3 {
          position: absolute;
          right: 30px;
          top: 115px;
        }
      }
      .quanli {
        margin-top: 10px;
        text-align: left;
        color: #434343;
        padding-left: 10px;
      }
    }
    .shoudo {
      height: 37px;
      line-height: 37px;
      background: #687bff;
      color: #fff;
      width: 90%;
      margin: 0 auto;
      font-size: 15px;
    }
    .fsfassets {
      background: url("../../../static/img/beijinga.png") no-repeat;
      background-size: 100% 100%;
      height: 262px;
      width: 90%;
      margin: 35px auto 0;
      padding: 26px 16px;
      color: #fff;
      .biaoti {
        text-align: left;
        font-size: 13px;
      }
      .jiege {
        font-size: 30px;
        font-weight: bold;
        margin-top: 20px;
      }
      .diyiba {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        li {
          width: 50%;
          margin-top: 20px;
          font-size: 13px;
          &:nth-child(-n+2) {
            margin-top: 15px;
          }
          .money {
            font-size: 18px;
            margin-bottom: 8px;
          }
        }
      }
    }
    .fanyongList {
      height: 129px;
      overflow: hidden;
      width: 90%;
      margin: 40px auto 0;
      background: #fff;
      border-radius: 4px;
      padding: 12px;
     ul {
       li {
         display: flex;
         align-items: center;
         justify-content: space-between;
         font-size: 14px;
         height: 21px;
         p span {
           color: red;
         }
       }
     }
    }
    .popup-container {
      height: 180px;
      width: 350px;
      margin: 0 auto;
      position: relative;
      background-color: #687bff;
      background-image: -webkit-linear-gradient(top,#687bff,#687bff 0,#fff 60%,#fff);
      .title {
        padding-top: 25px;
        font-size: 26px;
        font-weight: bold;
        color: #faefe2;
        text-shadow: 0.05333rem 0.05333rem 0.13333rem #000;
      }
      .closeIcon {
        position: absolute;
        right: 20px;
        top: 10px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 2px solid #fff;
      }
      .content {
        margin-top: 25px;
        text-align: left;
        background: #fff;
        position: relative;
        padding: 10px 15px 0;
        p {
          font-size: 13px;
          color: #9e9e9e;
          margin-bottom: 2px;
        }
        .dengdan {
          margin-top: 18px;
          font-size: 13px;
        }
        .qiangdna {
          width: 72px;
          height: 72px;
          position: absolute;
          top: -10px;
          right: 10px;
        }
      }

    }
  }
</style>